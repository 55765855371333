import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromEnduser from '../../../enduserPortal/reducers';
import * as fromAuth from '../../../auth/reducers';
import * as EnduserActions from '../../../enduserPortal/actions/enduser.actions';
import { Observable, Subscription } from 'rxjs';
import { EndUserSettings } from '../../../../types/zelo/zelo.types';
import { updateCurrentUser } from '../../../adminPortal/profile/actions/settings.actions';
import { User } from 'src/types';
import { NgIf, AsyncPipe } from '@angular/common';
import { IconComponent } from '../icon.component';
import { TranslatePipe } from '@ngx-translate/core';
@Component({
  selector: 'zlo-privacy-consent',
  templateUrl: 'privacy-consent.component.html',
  styleUrls: ['./privacy-consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, IconComponent, AsyncPipe, TranslatePipe]
})
export class PrivacyConsentComponent implements OnInit, OnDestroy {
  isVisible: boolean = false;
  @Input() isEndUser = false;
  subscriptions: Subscription = new Subscription();
  privacyLink: string = 'https://www.zeloapp.com/resources/privacy-policy';
  endUserSettings: EndUserSettings;
  endUserSettings$: Observable<EndUserSettings>;
  triggerObservable$: Observable<any>;
  user$ = this.store.pipe(select(fromAuth.getUser));
  user: User;
  constructor(private store: Store<fromEnduser.State>) {}

  ngOnInit() {
    if (this.isEndUser) {
      this.endUserSettings$ = this.store.pipe(
        select(fromEnduser.getEnduserSettings)
      );
      this.triggerObservable$ = this.endUserSettings$;

      this.subscriptions.add(
        this.endUserSettings$.subscribe((result) => {
          this.endUserSettings = result;
          this.isVisible =
            result?.latestTermsAccepted === result?.privacyVersion
              ? false
              : true;
          this.privacyLink = result?.privacyLink || this.privacyLink;
        })
      );
    } else {
      this.triggerObservable$ = this.user$;
      this.subscriptions.add(
        this.user$.subscribe((user) => {
          this.user = user;

          if (this.user) {
            this.endUserSettings = user.settings;
            this.privacyLink = user.privacyLink || this.privacyLink;
            this.isVisible = false;

            if (
              user.privacyVersion &&
              user.settings &&
              user.privacyVersion !== user.settings.latestTermsAccepted
            ) {
              this.isVisible = true;
            }
          }
        })
      );
    }
  }

  acceptPolicy() {
    const updatedSettings: EndUserSettings = {
      preferredChannel: null,
      preferredChannelId: null,
      preferredDay: null,
      preferredTime: null,
      preferredTimezone: null,
      phoneConsent: null,
      preferredLanguage: null,
      latestTermsAccepted: null,
      ...this.endUserSettings
    };

    updatedSettings.latestTermsAccepted =
      this.endUserSettings.privacyVersion || '1.0';

    if (this.isEndUser) {
      this.store.dispatch(
        EnduserActions.sendEndUserSettings({
          userId: this.endUserSettings.userId,
          endUserSettings: updatedSettings
        })
      );
    } else {
      const updatedUser = { ...this.user, settings: updatedSettings };
      this.store.dispatch(updateCurrentUser(updatedUser));
    }

    this.isVisible = false;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscriptions.unsubscribe();
  }
}
