import { Component } from '@angular/core';
import { PrivacyConsentComponent } from '../shared/components/privacy-consent/privacy-consent.component';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'zlo-end-user',
  template: `
    <zlo-privacy-consent isEndUser="true"></zlo-privacy-consent
    ><router-outlet></router-outlet>
  `,
  imports: [PrivacyConsentComponent, RouterOutlet]
})
export class EndUserComponent {}
